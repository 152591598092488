import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,[_c(VCard,{staticClass:"pa-4"},[_c(VCardTitle,[_vm._v(_vm._s(_vm.user.phone))]),_c(VCardText,[_c('UserTabLinks'),_c(VRow,{staticClass:"pl-3 pb-3"}),_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('td',[_vm._v("Дата")]),_c('td',[_vm._v("Ресторан")]),_c('td',[_vm._v("Номер")]),_c('td',[_vm._v("Статус")]),_c('td',[_vm._v("Сумма")])])]),_c('tbody',_vm._l((_vm.userOrders),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,'DD.MM.YYYY HH:mm')))]),_c('td',[_vm._v(" "+_vm._s(item.entity.title)+" ")]),_c('td',[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.open(item.id)}}},[_vm._v(_vm._s(item.external_id))])]),_c('td',[(_vm.statuses && _vm.statuses.orders)?[_c('span',{staticClass:"true-false-label primary",style:(`background: ${ _vm.statuses.orders[item.status].color } !important;`)},[_vm._v(" "+_vm._s(_vm.statuses.orders[item.status].title)+" ")])]:_vm._e()],2),_c('td',[_vm._v(" "+_vm._s(_vm.priceFormated(item.full_price))+" ")])])}),0)]},proxy:true}])})],1),_c(VCardActions,{staticClass:"pa-4 d-flex justify-space-between"},[_c('div',[_c(VBtn,{staticClass:"mr-3",attrs:{"color":"light"},on:{"click":_vm.goBack}},[_vm._v("Назад")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }