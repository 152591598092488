<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ user.phone }}</v-card-title>
                <v-card-text>
                    <UserTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <td>Дата</td>
                                    <td>Ресторан</td>
                                    <td>Номер</td>
                                    <td>Статус</td>
                                    <td>Сумма</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in userOrders" :key="item.id">
                                    <td>{{ item.created_at | moment('DD.MM.YYYY HH:mm') }}</td>
                                    <td>
                                        {{ item.entity.title }}
                                    </td>
                                    <td><a href="" @click.prevent="open(item.id)">{{ item.external_id }}</a></td>
                                    <td>
                                        <template v-if="statuses && statuses.orders">
                                            <span class="true-false-label primary" :style="`background: ${ statuses.orders[item.status].color } !important;`">
                                                {{ statuses.orders[item.status].title }}
                                            </span>
                                        </template>
                                    </td>
                                    <td>
                                        {{ priceFormated(item.full_price) }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn color="light" class="mr-3" @click="goBack" >Назад</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import UserTabLinks from '../../components/users/UserTabLinks';
    import { priceFormated } from '../../helpers';

    export default {
        name: 'UserOrders',

        components: {
            UserTabLinks,
        },

        data() {
            return {
                priceFormated,
                editModal: false,
                editUserEntity: {}
            };
        },

        computed: {
            ...mapState('users', {
                user: state => state.entity
            }),
            ...mapState('orders', {
                statuses: state => state.statuses
            }),
            userOrders() {
                return this.user && this.user.orders ? this.user.orders : [];
            }
        },

        async mounted() {
            await store.dispatch('orders/statuses');
            await store.dispatch('users/get', { id: this.$route.params.id });
        },

        methods: {
            async open(id) {
                await this.$router.push({ name: 'orders-detail', params: { id } });
            },
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/users/${id}/detail`);
                } else {
                    this.$router.push('/users');
                }
            },
        },
    };
</script>