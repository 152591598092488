<template>
    <AppTabLinks :links="list" />
</template>

<script>
    import { mapState } from 'vuex';
    import AppTabLinks from '@/components/AppTabLinks'

    export default {
        components: {
            AppTabLinks
        },
        computed: {
            ...mapState('auth', ['user']),
            isAdmin() {
                return this.user.admin;
            },
            list() {
                const list = [];
                list.push({
                    title: 'Карточка',
                    url: `/users/${this.$route.params.id}/detail`,
                    disabled: !this.$route.params.id
                });
                if(this.isAdmin){
                    list.push({
                        title: 'Редактировать',
                        url: `/users/${this.$route.params.id}/edit`
                    });
                    list.push({
                        title: 'Рестораны',
                        url: `/users/${this.$route.params.id}/sites`,
                        disabled: !this.$route.params.id
                    });
                }
                list.push({
                    title: 'Заказы',
                    url: `/users/${this.$route.params.id}/orders`,
                    disabled: !this.$route.params.id
                });
                return list;
            }
        },
        mounted() {
        },
    }
</script>
